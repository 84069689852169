import React, { useState, useEffect } from "react";
//SERVICES
import { getFoodInfo } from "./services/api";

function DigitalMenu() {
  const [foodInfo, setFoodInfo] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setFoodInfo(await getFoodInfo());
    }
    fetchData()
  }, [])

  const allImage = []
  foodInfo.forEach(element => {
    allImage.push(`${element.main.thumbnail}`)
  });
  const [numImage, setNumImage] = useState(allImage.length)
  let image = [allImage[numImage]]

  const allDescription = []
  foodInfo.forEach(element => {
    allDescription.push(`${element.main.description}`)
  });
  let descriptions = [allDescription[numImage]]

  const allImage1 = []
  foodInfo.forEach(element => {
    allImage1.push(`${element.files[0].url}`)
  });
  let image1 = [allImage1[numImage]]

  // const allImage2 = []
  // foodInfo.forEach(element => {
  //   allImage2.push(`${element.files[1].url}`)
  // });
  // let image2 = [allImage2[numImage]]


  const allBgColor = []
  foodInfo.forEach(element => {
    allBgColor.push(`${element.color}`)
  });
  let bgColor = [allBgColor[numImage]]

  const language = foodInfo.map((item) => item.language);
  let titleValues = [];
  for (let i = 0; i < language.length; i++) {
    let subArray = language[i];
    let titleObject = subArray.find(item => item.key === "title" && item.lang === "fa");

    if (titleObject) {
      titleValues.push(titleObject.value);
    } else {
      console.log("No object with key 'title' found in sub-array " + i);
    }
  }
  const numFood = titleValues.length;
  const degreeOfFood = 180 / numFood;
  const numDegreeOfDote = titleValues.length - 1;
  const degreeOfDote = 180 / numDegreeOfDote
  const [isRotate, setIsRotate] = useState(0);

  const nextClickHandler = () => {
    if (isRotate <= 179) {
      setIsRotate((prevRotate) => prevRotate + degreeOfDote);
      setNumImage(numImage + 1)
    } else {
      setIsRotate(0);
      setNumImage(0)
    }

  };
  const previousClickHandler = () => {
    if (isRotate > 0) {
      setIsRotate((prevRotate) => prevRotate - degreeOfDote);
      setNumImage(numImage - 1)
    } else {
      setIsRotate(180)
      setNumImage(numFood - 1)
    }
  };

  return (
    <div
    style={{background: `${bgColor[0]}`}}
     className={`flex justify-center  w-screen h-screen`}

    >
      {image1.map((item, index) =>
        <img
          key={index}
          href={`${item}`}
          alt="Custom"
          className=" p-10 w-2/6 h-1/3"

        />
      )}

      {descriptions.map((item, index) =>
        <p
        className="w-2/6 mt-44 text-center text-white text-lg font-medium"
        key={index}>{item}</p>)}


        {image.map((item, index) =>
              <img
                key={index}
                src={`${item}`}
                alt="Custom"
                className=" p-10 w-2/6 h-1/3"

              />
            )}

      {/* 
      {image2.map((item, index) =>
        <image
          key={index}
          href={`${item}`}
          alt="Custom Image"
          className=" p-10 w-full"

        />
      )} */}

      <div className="fixed bottom-2 left-[16%] w-2/3 justify-center">
        <svg
          className=""
          viewBox="0 0 100 50"
          strokeDasharray="1 1 1"
        >

          {titleValues.reverse().map((food, index) => (
            <text
              key={index}
              x={50 + 44 * Math.cos(-(index * degreeOfFood * Math.PI) / (180 - degreeOfFood))}
              y={50 + 44 * Math.sin(-(index * degreeOfFood * Math.PI) / (180 - degreeOfFood))}
              textAnchor="middle"
              fontSize="1.8"
              fill="white"
              onClick={() => {
                setIsRotate((numDegreeOfDote - index) * degreeOfDote)
                setNumImage(numDegreeOfDote - index)
              }}
              className="cursor-pointer"
            >

              {food}
            </text>
          ))}
          <g className="origin-[50%_100%] transition-transform duration-500" transform={`rotate(${isRotate} 0 0)`}>
            <circle r="38" cx="50" cy="50" stroke="gray" strokeWidth="0.1" fill="none" />
            <defs>
              <clipPath id="circleView">
                <circle cx="50" cy="50" r="34" />
              </clipPath>
            </defs>
            {image.map((item, index) =>
              <image
                key={index}
                href={`${item}`}
                alt="Custom Image"
                className=" p-10 w-full"
                clipPath="url(#circleView)"
              />
            )}
            <circle r="0.5" cx="12" cy="50" />
          </g>
        </svg>
        <div className="flex justify-between gap-x-60">
          <button onClick={previousClickHandler}>previous</button>
          <button onClick={nextClickHandler}>Next</button>
        </div>
      </div>
    </div>
  );
}
export default DigitalMenu;