import React from 'react'
//COMPONENTS
import DigitalMenu from './DigitalMenu';

const App=()=>{
  return(
    <>
    <DigitalMenu />
   </>
  )
}
export default App;
